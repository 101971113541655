import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = ({ location }) => (
  <Layout location={location}>
    <Container>
      <SEO title="Contato" />
      <Wrapper>
        <h1>Contato</h1>
      </Wrapper>
    </Container>
  </Layout>
)

const Container = styled.div`
  padding-top: 0px;
  margin-top: -26px;
  width: 100%;
`
const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  background-color: #fff;
`
export default ContactPage
